<template>
  <vx-card  style="padding-left: 10px">
    <div id="data-list-list-view" class="data-list-container">
      <vs-table
        ref="table"
        :sst="true"
        :total="totalDocs"
        :data="users"
        @search="handleSearch"
        @change-page="handleChangePage"
        @sort="handleSort"
        :max-items="dataTableParams.limit"
        :noDataText="noDataText"
        search
      >
        <div slot="header" class="flex flex-wrap-reverse flex-grow justify-between" >
          <div style='float: left'>
            <vs-select placeholder="10" vs-multiple autocomplete v-model="dataTableParams.limit" class="mr-6">
              <vs-select-item :key="index" :value="item.value" :text="item.text" v-for="(item,index) in limitOptions" :clearable="false"/>
            </vs-select>
          </div>
        </div>

        <template slot="thead">
          <vs-th sort-key="title">Title</vs-th>
          <vs-th sort-key="type">Reported Type</vs-th>
          <vs-th sort-key="fullName">Reported User By</vs-th>
          <vs-th sort-key="fullName">Reported To</vs-th>
          <vs-th sort-key="fullName">Reported Date</vs-th>
          <vs-th sort-key="message">Message</vs-th>
<!--          <vs-th sort-key="action">Action</vs-th>-->
        </template>

        <template slot-scope="{data}">
          <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
            <vs-td :data="data[indextr].title">
              {{ data[indextr].title | capitalize}}
            </vs-td>
            <vs-td :data="data[indextr].type">
              {{ data[indextr].type.replace(/([A-Z])/g, ' $1').trim() | capitalize}}
            </vs-td>
            <vs-td :data="data[indextr].user.fullName">
              {{ data[indextr].user.fullName }}  {{ typeof data[indextr].user.profileCreatedBy !== 'undefined'?'('+ data[indextr].user.profileCreatedBy+')':'' }}
            </vs-td>


            <vs-td :data="data[indextr].reportTo.fullName">
              {{ data[indextr].reportTo.fullName }}  ({{ data[indextr].reportTo.profileCreatedBy }})
            </vs-td>
            <vs-td :data="data[indextr].createdAt">{{ data[indextr].createdAt | date_formatter('DD/MM/YYYY HH:mm') }}</vs-td>

            <vs-td :data="data[indextr].description">
              {{ data[indextr].description }}
            </vs-td>
<!--            <vs-td :data="data[indextr]._id">-->
<!--              <vs-button type="border" size="small" @click="editDetailHandler(data[indextr]._id)" icon-pack="feather" icon="icon-edit" class="mr-2"></vs-button>-->
<!--            </vs-td>-->
          </vs-tr>
        </template>
      </vs-table>
      <div class="m-2">
        <span class="mr-2">
          {{
          dataTableParams.page * dataTableParams.limit -
          (dataTableParams.limit - 1)
          }}
          -
          {{
            totalDocs - dataTableParams.page * dataTableParams.limit > 0
              ? dataTableParams.page * dataTableParams.limit
              : totalDocs
          }}
          of {{ totalDocs }}
        </span>
        <div class="pagination-div" v-if="serverResponded">
          <paginate
            :page-count="totalPage"
            :click-handler="handleChangePage"
            class="pagination"
            :page-range="9"
            :prevText="'<'"
            :nextText="'>'"
          ></paginate>
        </div>
      </div>
    </div>
  </vx-card>
</template>

<script>
  import vSelect from 'vue-select'
  import { mapActions } from 'vuex';
  import moment from "moment";

  export default {
    components: {
      'v-select': vSelect,
    },
    data() {
      return {
        isMounted: false,
        noDataText:'No report issues available..',
        totalDocs: 0,
        currentPage: 1,
        dataTableParams: {
          search: "",
          sort: "createdAt",
          dir: "asc",
          page: 1,
          limit: 25
        },
        users: [],
        serverResponded: false,
        limitOptions: [
          { text: '5', value: 5 },
          { text: '10', value: 10 },
          { text: '25', value: 25 },
          { text: '50', value: 50 },
          { text: '100', value: 100 },
        ],
      };
    },
    methods: {
      ...mapActions('report', ['fetchReports']),
      moment(date){
        return moment(date)
      },
      getPagesList() {
        let self = this;
        this.fetchReports(self.dataTableParams).then(res => {
          self.users = res.data.data.docs;
          self.totalDocs = res.data.data.pagination.total;
          self.page = res.data.data.pagination.page;
        })
        this.serverResponded = true;
      },
      handleSearch(searching) {
        this.serverResponded = false;
        this.dataTableParams.search = searching;
        this.dataTableParams.page = 1;
        this.$refs.table.currentx = 1;
        this.getPagesList();
      },
      handleChangePage(page) {
        this.dataTableParams.page = page;
        this.getPagesList();
      },
      handleSort(key, active) {
        this.serverResponded = false;
        this.dataTableParams.sort = key;
        this.dataTableParams.dir = active;
        this.dataTableParams.page = 1;
        this.$refs.table.currentx = 1;
        this.getPagesList();
      },
      editDetailHandler(id) {
        this.$router.push({ name: 'CMSPageDetail', params: { id: id } })
      }
    },
    watch: {
      'dataTableParams.page': function(newVal, oldVal) {
        if(newVal !== oldVal){
          this.dataTableParams.page = newVal;
          this.getPagesList();
        }
      },
      'dataTableParams.limit': function(newlimit, oldLimit) {
        if(newlimit !== oldLimit){
          this.serverResponded = false;
          this.dataTableParams.page = 1;
          this.dataTableParams.limit = newlimit;
          this.getPagesList()
        }
      }
    },
    computed: {
      totalPage: function() {
        return this.totalDocs / this.dataTableParams.limit >
        parseInt(this.totalDocs / this.dataTableParams.limit)
          ? parseInt(this.totalDocs / this.dataTableParams.limit) + 1
          : parseInt(this.totalDocs / this.dataTableParams.limit);
      }
    },
    created() {
      this.getPagesList();
    }
  };
</script>
